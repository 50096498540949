.navbar-component {
    width: 100vw;
    margin: 0 !important;

}
.navbar-component .items-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin: 50px 0 50px 10vw;
}

.navbar-component .links-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 10vw 50px 0;
    padding: 40px 0 0 0; 
}

.navbar-component a {
    text-decoration: none;
}

.navbar-component h2 {
    color: black;
    margin: 7px 0;
}

.navbar-component .links-wrapper a {
    text-decoration: none;
    margin: 10px 20px;
}

.trabajo-wrapper {
    margin: 10px 0 10px 8vw;
    max-width: 90% !important;
}

.trabajo-wrapper .content h6 {
    font-family: 'Newsreader';
    font-weight: bold;
}

