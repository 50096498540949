.App {
    background: #F1F1F1;
}
.inicio-component {
    height: 100vh;
    width: 100vw;
    margin: 0 !important;
    padding: 0 !important;
}

.inicio-component .col-12 {
    height: 100vh;
}

.black-box {
    background: #1D1D1D;
    color: white;
}

.black-box img {
    width: 50%;
    height: auto;
    max-width: 250px;
}

.black-box .items-wrapper, .white-box .items-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;
}

.black-box .items-wrapper p {
    font-size: 30px;
    margin: 20px 0;
}

p, h2, h4, a {
    font-family: 'Newsreader';
}

.white-box {
    background: #F1F1F1;
}

.white-box .upper-links {
    margin: 10px 10px 0;
    padding: 5px;
    text-align: right;
    width: 100%;
}

.white-box .upper-links a {
    color: black;
    text-decoration: none;
    margin: 10px;
}

.white-box .texts {
    margin: 20px;
    padding: 30px 50px 10px;
    text-align: left;
    width: 100%;
}

.white-box .texts p {
    font-size: 14px
}

.white-box .lower-links {
    margin: 0px 20px;
    padding: 5px 40px;
    text-align: left;
    width: 100%;

    display: flex;
}

.white-box .lower-links div {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 10px;

    font-size: 11px;
}

.white-box .lower-links a {
    color: white;
    text-decoration: none;
}

.red {
    background: #E22146;
}
.blue {
    background: #33C5B9;
}
.yellow {
    background: #FFBD16;
}


.resume-wrapper, .skills-wrapper, .projects-wrapper {
    padding: 0px 8vw;
}

.profile-wrapper  {
    padding: 10px;
    display: flex;
    position: relative;
}

.profile-wrapper .num-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;

    background: #E22146;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-wrapper .num-circle p {
    margin: 0;
    padding: 0;
    font-size: 25px;
}

.profile-wrapper .resume-title {
    width: 50%;
    min-width: 200px;
    display: flex;
    align-items: center;
    min-height: 50px;
}

.profile-wrapper .resume-title p {
    min-width: 200px;
}

.profile-wrapper .resume-title p, .skills-wrapper .skill-title {
    margin: 0 20px;
    font-size: 25px;
}

.profile-wrapper .line, .skills-wrapper .line {
    min-width: 50px;
    min-height: 1px;
    width: 150px;
    height: 2px;
    position: absolute;
    left: 90px;
    top: 55px;
    border: solid #E22146 1px;
}

.profile-wrapper .inner-text {
    margin: 10px 0 10px 10vw;
    max-width: 90%;
}

.skills-wrapper {
    margin: 30px 0;
    position: relative;
}

.skills-wrapper .line {
    top: 40px;
    left: 120px;
    border: #FFBD16 solid 1px;
}

.skills-wrapper .bar-container {
    width: 100%;
    height: 20px;
    border: solid gray 1px;
    border-radius: 30px;
    padding: 2px;
}

.skills-wrapper .bar-content {
    height: 100%;
    border-radius: 20px;
    width: 0;
    transition: all 1s;
}

.projects-wrapper .img-wrapper {
    height: 300px;
    width: 100%;
    background: #1D1D1D;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.projects-wrapper .img-wrapper img {
    max-width: 100%;
    max-height: 100%;
}

.projects-wrapper .row {
    margin: 100px 0;
}

.projects-wrapper .texts-wrapper {
    padding: 10px;
}

.projects-wrapper .texts-wrapper h2 {
    font-weight: bold;
}

.projects-wrapper .texts-wrapper .date {
    color: gray;
}